<template>
  <article id="wrap">

    <form novalidate="true">

      <header>
        <ul class="page_head">
          <li class="menuL" v-if="!isConfirm"><router-link :to="{name:'login'}"><img src="@/assets/img/icon_back_white.png"></router-link></li>
          <li class="menuL" v-if="isConfirm"><a @click="back"><img src="@/assets/img/icon_back_white.png"></a></li>
          <li class="menuC"><h1 class="page_head_h1">会員登録</h1></li>
          <li class="menuR"></li>
        </ul>
      </header>


      <section v-if="!isConfirm" class="contents">

        <div class="contentInner">
          <MyUpload :image_b64="image_b64" @uploadImage="updateImage"></MyUpload>

          <div class="profile_text">会員情報はわたしのことから変更できます</div>

          <SignupFormProfile ref="profile" v-bind.sync="form" @update:error="setError"></SignupFormProfile>
          <SignupFormSNS v-bind.sync="form" @update:error="setError"></SignupFormSNS>

          <div class="formCheckbox">
            <div class="error" v-if="error && !accepted">
              同意するにチェックが入っていません
            </div>
            <input type="checkbox" v-model="accepted" id="checkbox01">
            <label for="checkbox01" class="checkbox">
              <router-link :to="{name: 'rule'}" target="_blank">利用規約</router-link>に同意する。
            </label>
          </div>

          <div class="bt_form"><button type="button" value="send" @click="next">確認</button></div>

        </div><!--.contentInner-->

      </section><!--.contents-->

      <SignupConfirm v-if="isConfirm" v-bind="form" :image_b64="image_b64" @send="send"></SignupConfirm>

    </form>

    <modal class="modal-wrapper" name="send" :adaptive="true" :scrollable="true"
           classes="modal-window" height="auto">
      <div class="modal-content">
        <div class="mb_20">登録されたメールアドレス宛にご案内を送信しました<br>
          届いたメールに記載されたURLをクリックして登録を完了してください。</div>
        <div class="bt_form mb_5"><a @click="finish">OK</a></div>
      </div>
    </modal>
  </article>
</template>

<script>
import SignupFormProfile from '@/components/SignupFormProfile';
import SignupFormSNS from '@/components/SignupFormSNS';
import SignupConfirm from '@/components/SignupConfirm';
import MyUpload from '@/components/MyUpload';
export default {
  name: 'SignupForm',
  components: {SignupFormProfile, SignupFormSNS, SignupConfirm, MyUpload},
  data: function () {
    return {
      form: {
        nick: '',
        first_name: '',
        last_name: '',
        first_kana: '',
        last_kana: '',
        birthday: '',
        email: '',
        password: '',
        password_confirm: '',
        comment: '',
        twitter: '',
        facebook: '',
        instagram: '',
      },
      image_b64: '',
      accepted: false,
      error: false,
      isConfirm: false,
    }
  },
  methods: {
    back () {
      this.$set(this, 'isConfirm', false);
      setTimeout(() => { this.$refs.profile.$v.$touch() }, 100);
    },
    next () {
      // validation
      this.error = this.$refs.profile.invalid();
      // 追加のバリデーション 利用規約チェック必須
      if (!this.accepted) {
        this.error = true;
      }

      if (!this.error) {
        this.isConfirm = true;
        // this.$router.push({name:'signup-confirm'});
      } else {
        document.scrollingElement.scrollTop = 0;
      }
    },
    setError ($event) {
      this.error = $event;
    },
    async updateImage ($event) {
      this.image_b64 = $event;
    },
    async send () {
      try {
        await this.$http.post('users', Object.assign(
            this.form, {
              image_b64: this.image_b64
            }));
      } catch (e) {
        console.log(e.response);
        this.$swal(e.message);
        this.error = true;
        this.isConfirm = false;
        return;
      }

      this.$modal.show('send')
      // this.$http.post('users', this.form);
    },
    finish () {
      this.$router.push({name: 'home'});
    }
  }
}
</script>

<style lang="scss" scoped>
#wrap {
  .error {
    font-size: 10rem;
    color: #ff7666;
    margin: 5px 0 -5px;
  }
}
</style>